
const Privacy = () => {
    return (
        <>    
            <div class="extra-page">
                <section id="hero-section" class="extra-page">
                    <div class="row hero-unit">
                        <div class="container">
                            <div class="row text-center">
                                <div class="col-md-12">
                                    <div class="hero-caption">
                                        <h1>Privacy Policy</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </section>
                    <nav aria-label="breadcrumb" class="small-margin breadcrumb-nav">
                        <div class="container">
                            <div class="row">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Privacy Policy</li>
                                </ol>
                            </div>
                        </div>
                    </nav>

                <section class="extra-page small-margin">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-10 offset-lg-1">

                                <div class="text-container tiny-margin">
                                    <h3>Costa Interactive Limited Privacy Policy</h3>
                                    <p>Effective Date: January 1, 2024</p>

                                    <p>Costa Interactive Limited ("Costa Interactive," "we," "our," or "us") respects your privacy and is committed to protecting it through our compliance with this Privacy Policy. This Privacy Policy describes the types of information we may collect from you or that you may provide when you visit the website [Website URL] (our "Website") and our practices for collecting, using, maintaining, protecting, and disclosing that information.</p>
                                </div>
                                <div class="text-container tiny-margin">
                                    <h3>Information We Collect About You and How We Collect It</h3>

                                    <p>We collect several types of information from and about users of our Website, including information:</p>

                                    <ol>                                                                            
                                        <li>By which you may be personally identified, such as name, postal address, email address, telephone number, or any other identifier by which you may be contacted online or offline ("personal information").</li>
                                        <li>That is about you but individually does not identify you.</li>
                                        <li>About your internet connection, the equipment you use to access our Website, and usage details.</li>
                                    </ol>

                                    <p>We collect this information:</p>

                                    <ol>
                                        <li>Directly from you when you provide it to us.</li>
                                        <li>Automatically as you navigate through the site. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies.</li>
                                    </ol>
                                </div>
                                <div class="text-container tiny-margin">
                                    <h3>How We Use Your Information</h3>

                                    <p>We use information that we collect about you or that you provide to us, including any personal information:</p>

                                    <ol>
                                        <li>To present our Website and its contents to you.</li>
                                        <li>To provide you with information, products, or services that you request from us.</li>
                                        <li>To fulfill any other purpose for which you provide it.</li>
                                        <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us.</li>
                                    </ol>
                                </div>
                                <div class="text-container tiny-margin">
                                    <h3>Disclosure of Your Information</h3>

                                    <p>We may disclose personal information that we collect or you provide as described in this Privacy Policy:</p>

                                    <ol>
                                        <li>To our subsidiaries and affiliates.</li>
                                        <li>To contractors, service providers, and other third parties we use to support our business.</li>
                                        <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Costa Interactive Limited's assets.</li>
                                    </ol>
                                </div>
                                <div class="text-container tiny-margin">
                                    <h3>Your Choices About Our Collection, Use, and Disclosure of Your Information</h3>

                                    <ol>
                                        <li>- We strive to provide you with choices regarding the personal information you provide to us. You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent.</li>
                                    </ol>
                                </div>
                                <div class="text-container tiny-margin">
                                    <h3>Data Security</h3>

                                    <p>We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls.</p>
                                </div>
                                <div class="text-container tiny-margin">
                                    <h3>Changes to Our Privacy Policy</h3>

                                    <p>It is our policy to post any changes we make to our Privacy Policy on this page. If we make material changes to how we treat our users' personal information, we will notify you through a notice on the Website home page.</p>
                                </div>
                                <div class="text-container tiny-margin">
                                    <h3>Contact Information</h3>

                                    <p>To ask questions or comment about this Privacy Policy and our privacy practices, contact us at:</p>
                                    <p>privacy@costainteractive.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <nav aria-label="breadcrumb" class="breadcrumb-nav">
                    <div class="container">
                        <div class="row">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Privacy Policy</li>
                            </ol>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    );
};

export default Privacy;
