
const Header = () => {
    return (
        <>
            <header id="main-header">
                <nav class="navbar fixed-top navbar-expand-lg navbar-light">
                    <div class="container">
                        <a id="main-logo" class="navbar-brand" href="./">
                            <img src="images/logo.png" id="main-logo" class="navbar-brand" alt="logo" />
                        </a>
                        <button id="hamburger" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav ml-auto">
                                <li class="nav-item">
                                    <a class="nav-link" href="./#about">About</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="./#media">Media</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="./#features">Features</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="./#cta">Purchase</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
};

export default Header;
