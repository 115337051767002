
const NoPage = () => {
    return (
        <>
            <h1>NoPage</h1>
        </>
    );
};

export default NoPage;
