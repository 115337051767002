
const Footer = () => {
    return (
        <>
            <div id="footer">
                <div class="container">
                    {/* <div class="row text-center">
                        <div class="col-md-12">
                            <div class="row brands">
                                <div class="col-md-4">
                                    <a href="#">
                                        <img src="images/brand1.png" class="img-fluid" />
                                    </a>
                                </div>
                                <div class="col-md-4">
                                    <a href="#">
                                        <img src="images/brand2.png" class="img-fluid" />
                                    </a>
                                </div>
                                <div class="col-md-4">
                                    <a href="#">
                                        <img src="images/brand3.png" class="img-fluid" />
                                    </a>
                                </div>
                            </div>
                            <ul>
                                <li><a href="./privacy">Privacy &amp; Cookie Policy</a></li>
                                <li><a href="./terms">Terms &amp; Conditions</a></li>
                            </ul>
                        </div>
                    </div> */}
                    <div class="row text-center">
                        <div class="col-md-12">
                            <p id="copyright">&copy; Costa Interactive Limited</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;
